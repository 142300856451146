import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { Routes } from '@routes/routes';
import { formatDateTime } from '@utils/dates/format-date-time';
import { createColumnFactory } from '@utils/create-column.factory';
import { BooleanChip } from '@utils/render-boolean';
import { renderMoney } from '@utils/money/render-money';
import { ResultCell } from '../ResultCell';
import { Result } from '../bulk-action-row-result.type';
import { ISessionInvoiceSummary } from './session-invoice-summary.interface';
import { PDFCell } from './PDFCell';

type SummaryWithResult = ISessionInvoiceSummary & { result: Result | null };

const createSessionInvoiceSummaryColumn = createColumnFactory<ISessionInvoiceSummary>();

export const sessionInvoiceColumns = [
  createSessionInvoiceSummaryColumn('id', {
    width: 80,
    headerName: 'IDs',
    description: 'CustomerId/OrderId',
    renderCell: ({ row: summary }) => (
      <Link
        key={summary.id}
        component={NavLink}
        to={`${Routes.orderSummaries}/${summary.customerId}/${summary.orderId}`}
      >
        {summary.customerId}/{summary.orderId}
      </Link>
    ),
  }),
  createSessionInvoiceSummaryColumn('startMoment', {
    headerName: 'Session Date Time',
    width: 123,
    renderCell: ({ row }) => formatDateTime(row.startMoment),
  }),
  createSessionInvoiceSummaryColumn('duration', {
    headerName: 'Duration',
    width: 65,
  }),
  createSessionInvoiceSummaryColumn('customerFullName', {
    headerName: 'Customer',
    width: 150,
  }),
  createSessionInvoiceSummaryColumn('customerCrn', {
    headerName: 'CRN',
    width: 98,
  }),
  createSessionInvoiceSummaryColumn('tutorFullName', {
    headerName: 'Tutor',
    flex: 1,
  }),
  createSessionInvoiceSummaryColumn('isCancelled', {
    headerName: 'Cancelled',
    width: 70,
    renderCell: ({ row }) => <BooleanChip value={row.isCancelled} isInvertedColor />,
  }),
  createSessionInvoiceSummaryColumn('cancellationReason', {
    headerName: 'Cancellation Reason',
    flex: 1,
  }),
  createSessionInvoiceSummaryColumn('hourlyRate', {
    headerName: 'Hourly Rate',
    width: 90,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
    renderCell: ({ row }) => renderMoney(row.hourlyRate),
  }),
  createSessionInvoiceSummaryColumn('funderInvoice', { headerName: 'Funder Invoice', width: 110 }),
  createSessionInvoiceSummaryColumn('supplierInvoice', { headerName: 'Supplier Invoice', width: 110 }),
  createSessionInvoiceSummaryColumn('isPaid', {
    headerName: 'Paid',
    width: 60,
    renderCell: ({ row }) => <BooleanChip value={row.isPaid} />,
  }),
  createSessionInvoiceSummaryColumn('dipReference', {
    headerName: 'DIP',
    width: 70,
  }),
  createSessionInvoiceSummaryColumn('organisationId', {
    headerName: 'Organisation',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.organisationId} optionKey="organisationOptions" />,
    optionKey: 'organisationOptions',
  }),
  createSessionInvoiceSummaryColumn('timesheetPath', {
    headerName: 'Timesheet',
    flex: 1,
    renderCell: ({ row }) => <PDFCell path={row.timesheetPath} />,
  }),
];

const createColumnWithResult = createColumnFactory<SummaryWithResult>();

export const sessionInvoiceColumnsWithResult = sessionInvoiceColumns.concat(
  createColumnWithResult('result', {
    headerName: 'Result',
    width: 100,
    renderCell: ({ row }) => <ResultCell result={row.result} />,
  }),
);
